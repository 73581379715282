.layout-wrapper {
  display: flex;

  .layout-sidebar {
    position: fixed;
    width: 20rem;
    height: 100vh;
    z-index: 999;
    overflow-y: auto;
    user-select: none;
    top: 4rem;
    transition: transform $transitionDuration, left $transitionDuration;
    background-color: var(--surface-overlay);
    padding: 0.5rem 1.5rem;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05),
      0px 1px 4px rgba(0, 0, 0, 0.08);
    &.collapsed {
      width: 3.75rem;
      padding: 0.5rem;
    }
  }

  .layout-main-container {
    flex: 1;
    margin-left: 20rem;
    transition: margin-left 0.3s;

    &.sidebar-expanded {
      margin-left: 20rem;
      width: calc(100vw - 20rem);
    }

    &:not(.sidebar-expanded) {
      margin-left: 3.75rem;
      width: calc(100vw - 3.75rem);
    }
  }

  .layout-menu {
    margin: 0;
    padding: 0;
    list-style-type: none;

    .layout-root-menuitem {
      > .layout-menuitem-root-text {
        font-size: 0.857rem;
        text-transform: uppercase;
        font-weight: 700;
        color: var(--surface-900);
        margin: 0.75rem 0;
      }

      > a {
        display: none;
      }
    }

    a {
      display: flex;
      align-items: center;
      position: relative;
      outline: 0 none;
      color: var(--text-color);
      cursor: pointer;
      padding: 0.75rem 1rem;
      border-radius: $borderRadius;
      transition: background-color $transitionDuration,
        box-shadow $transitionDuration;

      .layout-menuitem-icon {
        margin-right: 0.5rem;
      }

      .layout-submenu-toggler {
        font-size: 75%;
        margin-left: auto;
        transition: transform $transitionDuration;
      }

      &.active-route {
        font-weight: 500;
        color: var(--primary-color);
      }

      &:hover {
        background-color: var(--surface-hover);
      }

      &:focus {
        @include focused-inset();
      }

      &.active-menuitem {
        > .layout-submenu-toggler {
          transform: rotate(-180deg);
        }
      }
    }

    li.active-menuitem {
      > a {
        .layout-submenu-toggler {
          transform: rotate(-180deg);
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      ul {
        overflow: hidden;
        border-radius: $borderRadius;

        li {
          a {
            margin-left: 1rem;
          }

          li {
            a {
              margin-left: 2rem;
            }

            li {
              a {
                margin-left: 2.5rem;
              }

              li {
                a {
                  margin-left: 3rem;
                }

                li {
                  a {
                    margin-left: 3.5rem;
                  }

                  li {
                    a {
                      margin-left: 4rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
