$couleur_rejet:#ff4032; //Rouge
$couleur_rejet2:#9c27b0; //Violet
$couleur_alerte:#f57c00; // Orange
$couleur_ignore:#fbc02d; // Jaune
$couleur_info:#3b82f6; //Bleu

.texte-critique {
    font-weight: bold;
}

.texte-rejet {
  color: $couleur_rejet;
}

.texte-rejet2 {
  color: $couleur_rejet2;
}

.texte-alerte {
  color: $couleur_alerte;
}

.texte-ignore {
  color: $couleur_ignore;
}

.texte-info {
  color: $couleur_info;
}

.tag-rejet > span:last-child {
    color: $couleur_rejet;
    background-color: #fee2e2;
}

.tag-rejet2 > span:last-child {
    color: $couleur_rejet2;
    background-color: #f3e8ff;
}


.tag-alerte > span:last-child {
    color: $couleur_alerte;
    background-color: #ffedd5;
}

.tag-ignore > span:last-child {
    color: $couleur_ignore;
    background-color: #fef9c3;
}

.tag-information > span:last-child {
    color: $couleur_info;
    background-color: #e0f2fe;
}