.p-fieldset {
  .p-fieldset-legend {
    border: none;
    background: transparent;
  }

  .p-fieldset-content {
    padding: unset;
  }
}
