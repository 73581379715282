$gutter: 1rem; //for primeflex grid system
@import 'assets/layout/sass/layout.scss';

@import '../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css';

@import '../node_modules/primeng/resources/themes/saga-blue/theme.css';
@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeflex/primeflex.scss';
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/datatables.net-dt/css/dataTables.dataTables.css';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-grid-tile-content {
  align-items: start !important;
  justify-content: flex-start !important;
}

.mat-grid-tile-content mat-card {
  width: 100%;
}
mat-grid-list {
  height: 100%;
}

button.mat-primary {
  background-color: #0072bb !important;
}

.max-h-60rem {
  max-height: 60rem;
}

input.form-control.left-status,
textarea.form-control.left-status,
input.ng-valid[required],
input.ng-valid.required,
textarea.ng-valid[required],
textarea.ng-valid.required,
p-dropdown.ng-valid[required] > div,
p-dropdown.ng-valid.required > div {
  border-left: 3px solid rgb(67, 163, 2);
}

input.ng-invalid,
textarea.ng-invalid,
p-dropdown.ng-invalid > div {
  border-left: 3px solid red;
}

:host ::ng-deep .p-datatable-bordered .p-datatable-thead > tr > th {
  border-width: 1px;
}

:host ::ng-deep .p-datatable-bordered .p-datatable-tbody > tr > td {
  border-width: 1px;
}

:host ::ng-deep .p-datatable-bordered {
  border: 1px solid #dee2e6;
}

:host ::ng-deep .p-datatable-thead > tr > th {
  background-color: #f8f9fa;
}

.chaine-title-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.chaine-title {
  white-space: nowrap;
  margin-right: 10px;
  font-size: 1.2rem;
  font-weight: 500;
}

.chaine-title-line {
  flex-grow: 1;
  height: 1px;
  background: #000;
}

.card {
  transition: background-color 0.3s ease;
}

.tracteur {
  border-top: 1px solid #d2d2d2;
  width: 3rem;
  display: flex;
}

.text-red-600 {
  color: red;
}
.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.6);
  z-index: 1000;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
    
  to {
    transform: rotate(360deg);
  }
}
    
.custom-circular-avatar-sm {
  width: 1.3rem;
  height: 1.3rem;
  font-size: 0.5rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
    
.rotating {
  animation: rotate 4s linear infinite;
}
    
.rotating:hover {
  animation-play-state: paused;
}